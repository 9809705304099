import { render, staticRenderFns } from "./BoxplotContainer.vue?vue&type=template&id=3aa95c8b"
import script from "./BoxplotContainer.vue?vue&type=script&lang=js"
export * from "./BoxplotContainer.vue?vue&type=script&lang=js"
import style0 from "./BoxplotContainer.vue?vue&type=style&index=0&id=3aa95c8b&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports