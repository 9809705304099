export default [
  {
    Case_ID: "C3N-00831",
    Specimen_ID: "C3N-00831-01",
    Slide_ID: "C3N-00831-21",
    Specimen_Type: "tumor_tissue",
    Weight: 196.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00831",
    Specimen_ID: "C3N-00831-02",
    Slide_ID: "C3N-00831-22",
    Specimen_Type: "tumor_tissue",
    Weight: 173.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00831",
    Specimen_ID: "C3N-00831-05",
    Slide_ID: "C3N-00831-25",
    Specimen_Type: "normal_tissue",
    Weight: 405.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00832",
    Specimen_ID: "C3N-00832-01",
    Slide_ID: "C3N-00832-21",
    Specimen_Type: "tumor_tissue",
    Weight: 289.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00834",
    Specimen_ID: "C3N-00834-01",
    Slide_ID: "C3N-00834-21",
    Specimen_Type: "tumor_tissue",
    Weight: 208.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00834",
    Specimen_ID: "C3N-00834-05",
    Slide_ID: "C3N-00834-25",
    Specimen_Type: "normal_tissue",
    Weight: 585.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01175",
    Specimen_ID: "C3N-01175-01",
    Slide_ID: "C3N-01175-21",
    Specimen_Type: "tumor_tissue",
    Weight: 221.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01175",
    Specimen_ID: "C3N-01175-05",
    Slide_ID: "C3N-01175-25",
    Specimen_Type: "normal_tissue",
    Weight: 288.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01176",
    Specimen_ID: "C3N-01176-01",
    Slide_ID: "C3N-01176-21",
    Specimen_Type: "tumor_tissue",
    Weight: 418.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-01176",
    Specimen_ID: "C3N-01176-05",
    Slide_ID: "C3N-01176-25",
    Specimen_Type: "normal_tissue",
    Weight: 545.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01178",
    Specimen_ID: "C3N-01178-01",
    Slide_ID: "C3N-01178-21",
    Specimen_Type: "tumor_tissue",
    Weight: 299.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01178",
    Specimen_ID: "C3N-01178-02",
    Slide_ID: "C3N-01178-22",
    Specimen_Type: "tumor_tissue",
    Weight: 512.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01179",
    Specimen_ID: "C3N-01179-01",
    Slide_ID: "C3N-01179-21",
    Specimen_Type: "tumor_tissue",
    Weight: 240.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 3.0,
  },
  {
    Case_ID: "C3N-01178",
    Specimen_ID: "C3N-01178-05",
    Slide_ID: "C3N-01178-25",
    Specimen_Type: "normal_tissue",
    Weight: 418.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01179",
    Specimen_ID: "C3N-01179-02",
    Slide_ID: "C3N-01179-22",
    Specimen_Type: "tumor_tissue",
    Weight: 205.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 3.0,
  },
  {
    Case_ID: "C3N-00495",
    Specimen_ID: "C3N-00495-05",
    Slide_ID: "C3N-00495-25",
    Specimen_Type: "normal_tissue",
    Weight: 365.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01179",
    Specimen_ID: "C3N-01179-05",
    Slide_ID: "C3N-01179-25",
    Specimen_Type: "normal_tissue",
    Weight: 475.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01180",
    Specimen_ID: "C3N-01180-01",
    Slide_ID: "C3N-01180-21",
    Specimen_Type: "tumor_tissue",
    Weight: 252.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00305",
    Specimen_ID: "C3N-00305-01",
    Slide_ID: "C3N-00305-21",
    Specimen_Type: "tumor_tissue",
    Weight: 397.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00305",
    Specimen_ID: "C3N-00305-02",
    Slide_ID: "C3N-00305-22",
    Specimen_Type: "tumor_tissue",
    Weight: 430.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00154",
    Specimen_ID: "C3N-00154-03",
    Slide_ID: "C3N-00154-23",
    Specimen_Type: "tumor_tissue",
    Weight: 1010.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01180",
    Specimen_ID: "C3N-01180-02",
    Slide_ID: "C3N-01180-22",
    Specimen_Type: "tumor_tissue",
    Weight: 254.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00310",
    Specimen_ID: "C3N-00310-03",
    Slide_ID: "C3N-00310-23",
    Specimen_Type: "tumor_tissue",
    Weight: 498.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00310",
    Specimen_ID: "C3N-00310-02",
    Slide_ID: "C3N-00310-22",
    Specimen_Type: "tumor_tissue",
    Weight: 460.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 65.0,
    Percent_Total_Cellularity: 65.0,
    Percent_Necrosis: 25.0,
  },
  {
    Case_ID: "C3N-00310",
    Specimen_ID: "C3N-00310-01",
    Slide_ID: "C3N-00310-21",
    Specimen_Type: "tumor_tissue",
    Weight: 723.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 20.0,
  },
  {
    Case_ID: "C3N-00310",
    Specimen_ID: "C3N-00310-04",
    Slide_ID: "C3N-00310-24",
    Specimen_Type: "tumor_tissue",
    Weight: 375.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 75.0,
    Percent_Total_Cellularity: 65.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3N-00310",
    Specimen_ID: "C3N-00310-05",
    Slide_ID: "C3N-00310-25",
    Specimen_Type: "normal_tissue",
    Weight: 507.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00312",
    Specimen_ID: "C3N-00312-01",
    Slide_ID: "C3N-00312-21",
    Specimen_Type: "tumor_tissue",
    Weight: 434.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00312",
    Specimen_ID: "C3N-00312-02",
    Slide_ID: "C3N-00312-22",
    Specimen_Type: "tumor_tissue",
    Weight: 590.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01361",
    Specimen_ID: "C3N-01361-01",
    Slide_ID: "C3N-01361-21",
    Specimen_Type: "tumor_tissue",
    Weight: 223.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-00312",
    Specimen_ID: "C3N-00312-05",
    Slide_ID: "C3N-00312-25",
    Specimen_Type: "normal_tissue",
    Weight: 648.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00312",
    Specimen_ID: "C3N-00312-04",
    Slide_ID: "C3N-00312-24",
    Specimen_Type: "tumor_tissue",
    Weight: 416.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00312",
    Specimen_ID: "C3N-00312-03",
    Slide_ID: "C3N-00312-23",
    Specimen_Type: "tumor_tissue",
    Weight: 585.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00313",
    Specimen_ID: "C3N-00313-03",
    Slide_ID: "C3N-00313-23",
    Specimen_Type: "tumor_tissue",
    Weight: 777.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-00313",
    Specimen_ID: "C3N-00313-02",
    Slide_ID: "C3N-00313-22",
    Specimen_Type: "tumor_tissue",
    Weight: 935.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00314",
    Specimen_ID: "C3N-00314-01",
    Slide_ID: "C3N-00314-21",
    Specimen_Type: "tumor_tissue",
    Weight: 239.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 50.0,
    Percent_Total_Cellularity: 50.0,
    Percent_Necrosis: 20.0,
  },
  {
    Case_ID: "C3N-00314",
    Specimen_ID: "C3N-00314-02",
    Slide_ID: "C3N-00314-22",
    Specimen_Type: "tumor_tissue",
    Weight: 495.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 20.0,
    Percent_Total_Cellularity: 40.0,
    Percent_Necrosis: 50.0,
  },
  {
    Case_ID: "C3N-00314",
    Specimen_ID: "C3N-00314-03",
    Slide_ID: "C3N-00314-23",
    Specimen_Type: "tumor_tissue",
    Weight: 514.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 30.0,
    Percent_Total_Cellularity: 30.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3N-00313",
    Specimen_ID: "C3N-00313-01",
    Slide_ID: "C3N-00313-21",
    Specimen_Type: "tumor_tissue",
    Weight: 597.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 50.0,
    Percent_Total_Cellularity: 65.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3N-00313",
    Specimen_ID: "C3N-00313-04",
    Slide_ID: "C3N-00313-24",
    Specimen_Type: "tumor_tissue",
    Weight: 1086.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 20.0,
  },
  {
    Case_ID: "C3N-00314",
    Specimen_ID: "C3N-00314-04",
    Slide_ID: "C3N-00314-24",
    Specimen_Type: "tumor_tissue",
    Weight: 480.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00314",
    Specimen_ID: "C3N-00314-05",
    Slide_ID: "C3N-00314-25",
    Specimen_Type: "normal_tissue",
    Weight: 424.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00315",
    Specimen_ID: "C3N-00315-01",
    Slide_ID: "C3N-00315-21",
    Specimen_Type: "tumor_tissue",
    Weight: 370.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell, partly papillary carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3N-00315",
    Specimen_ID: "C3N-00315-03",
    Slide_ID: "C3N-00315-23",
    Specimen_Type: "tumor_tissue",
    Weight: 397.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell, partly papillary carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-00315",
    Specimen_ID: "C3N-00315-04",
    Slide_ID: "C3N-00315-24",
    Specimen_Type: "tumor_tissue",
    Weight: 296.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell, partly papillary carcinoma",
    Percent_Tumor_Nuclei: 50.0,
    Percent_Total_Cellularity: 50.0,
    Percent_Necrosis: 40.0,
  },
  {
    Case_ID: "C3N-00315",
    Specimen_ID: "C3N-00315-02",
    Slide_ID: "C3N-00315-22",
    Specimen_Type: "tumor_tissue",
    Weight: 392.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell, partly papillary carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 20.0,
  },
  {
    Case_ID: "C3N-00317",
    Specimen_ID: "C3N-00317-03",
    Slide_ID: "C3N-00317-23",
    Specimen_Type: "tumor_tissue",
    Weight: 135.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 20.0,
    Percent_Total_Cellularity: 30.0,
    Percent_Necrosis: 20.0,
  },
  {
    Case_ID: "C3N-01361",
    Specimen_ID: "C3N-01361-02",
    Slide_ID: "C3N-01361-22",
    Specimen_Type: "tumor_tissue",
    Weight: 194.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01361",
    Specimen_ID: "C3N-01361-06",
    Slide_ID: "C3N-01361-26",
    Specimen_Type: "normal_tissue",
    Weight: 475.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00495",
    Specimen_ID: "C3N-00495-02",
    Slide_ID: "C3N-00495-22",
    Specimen_Type: "tumor_tissue",
    Weight: 335.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01651",
    Specimen_ID: "C3N-01651-01",
    Slide_ID: "C3N-01651-21",
    Specimen_Type: "tumor_tissue",
    Weight: 287.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01651",
    Specimen_ID: "C3N-01651-05",
    Slide_ID: "C3N-01651-25",
    Specimen_Type: "tumor_tissue",
    Weight: 162.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-01646",
    Specimen_ID: "C3N-01646-10",
    Slide_ID: "C3N-01646-30",
    Specimen_Type: "normal_tissue",
    Weight: 256.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01646",
    Specimen_ID: "C3N-01646-02",
    Slide_ID: "C3N-01646-22",
    Specimen_Type: "tumor_tissue",
    Weight: 198.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-01648",
    Specimen_ID: "C3N-01648-02",
    Slide_ID: "C3N-01648-22",
    Specimen_Type: "tumor_tissue",
    Weight: 129.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01648",
    Specimen_ID: "C3N-01648-01",
    Slide_ID: "C3N-01648-21",
    Specimen_Type: "tumor_tissue",
    Weight: 121.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-01649",
    Specimen_ID: "C3N-01649-01",
    Slide_ID: "C3N-01649-21",
    Specimen_Type: "tumor_tissue",
    Weight: 161.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01646",
    Specimen_ID: "C3N-01646-01",
    Slide_ID: "C3N-01646-21",
    Specimen_Type: "tumor_tissue",
    Weight: 303.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-00317",
    Specimen_ID: "C3N-00317-02",
    Slide_ID: "C3N-00317-22",
    Specimen_Type: "tumor_tissue",
    Weight: 366.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 60.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 20.0,
  },
  {
    Case_ID: "C3N-00317",
    Specimen_ID: "C3N-00317-05",
    Slide_ID: "C3N-00317-25",
    Specimen_Type: "normal_tissue",
    Weight: 316.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00317",
    Specimen_ID: "C3N-00317-01",
    Slide_ID: "C3N-00317-21",
    Specimen_Type: "tumor_tissue",
    Weight: 117.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 0.0,
    Percent_Total_Cellularity: 30.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3N-00320",
    Specimen_ID: "C3N-00320-01",
    Slide_ID: "C3N-00320-21",
    Specimen_Type: "tumor_tissue",
    Weight: 345.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00320",
    Specimen_ID: "C3N-00320-02",
    Slide_ID: "C3N-00320-22",
    Specimen_Type: "tumor_tissue",
    Weight: 411.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00320",
    Specimen_ID: "C3N-00320-04",
    Slide_ID: "C3N-00320-24",
    Specimen_Type: "tumor_tissue",
    Weight: 205.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00317",
    Specimen_ID: "C3N-00317-04",
    Slide_ID: "C3N-00317-24",
    Specimen_Type: "tumor_tissue",
    Weight: 369.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00320",
    Specimen_ID: "C3N-00320-03",
    Slide_ID: "C3N-00320-23",
    Specimen_Type: "tumor_tissue",
    Weight: 208.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-01648",
    Specimen_ID: "C3N-01648-08",
    Slide_ID: "C3N-01648-28",
    Specimen_Type: "normal_tissue",
    Weight: 288.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00320",
    Specimen_ID: "C3N-00320-05",
    Slide_ID: "C3N-00320-25",
    Specimen_Type: "normal_tissue",
    Weight: 498.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00437",
    Specimen_ID: "C3N-00437-01",
    Slide_ID: "C3N-00437-21",
    Specimen_Type: "tumor_tissue",
    Weight: 280.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00437",
    Specimen_ID: "C3N-00437-02",
    Slide_ID: "C3N-00437-22",
    Specimen_Type: "tumor_tissue",
    Weight: 395.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00437",
    Specimen_ID: "C3N-00437-05",
    Slide_ID: "C3N-00437-25",
    Specimen_Type: "normal_tissue",
    Weight: 486.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00437",
    Specimen_ID: "C3N-00437-03",
    Slide_ID: "C3N-00437-23",
    Specimen_Type: "tumor_tissue",
    Weight: 338.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00491",
    Specimen_ID: "C3N-00491-01",
    Slide_ID: "C3N-00491-21",
    Specimen_Type: "normal_tissue",
    Weight: 289.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00491",
    Specimen_ID: "C3N-00491-04",
    Slide_ID: "C3N-00491-24",
    Specimen_Type: "normal_tissue",
    Weight: 304.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00491",
    Specimen_ID: "C3N-00491-03",
    Slide_ID: "C3N-00491-23",
    Specimen_Type: "normal_tissue",
    Weight: 357.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00491",
    Specimen_ID: "C3N-00491-02",
    Slide_ID: "C3N-00491-22",
    Specimen_Type: "normal_tissue",
    Weight: 318.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00492",
    Specimen_ID: "C3N-00492-01",
    Slide_ID: "C3N-00492-21",
    Specimen_Type: "tumor_tissue",
    Weight: 374.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3N-00492",
    Specimen_ID: "C3N-00492-03",
    Slide_ID: "C3N-00492-23",
    Specimen_Type: "tumor_tissue",
    Weight: 288.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 25.0,
  },
  {
    Case_ID: "C3N-00492",
    Specimen_ID: "C3N-00492-02",
    Slide_ID: "C3N-00492-22",
    Specimen_Type: "tumor_tissue",
    Weight: 416.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 60.0,
    Percent_Total_Cellularity: 50.0,
    Percent_Necrosis: 50.0,
  },
  {
    Case_ID: "C3N-01649",
    Specimen_ID: "C3N-01649-02",
    Slide_ID: "C3N-01649-22",
    Specimen_Type: "tumor_tissue",
    Weight: 228.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01649",
    Specimen_ID: "C3N-01649-10",
    Slide_ID: "C3N-01649-30",
    Specimen_Type: "normal_tissue",
    Weight: 301.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01651",
    Specimen_ID: "C3N-01651-10",
    Slide_ID: "C3N-01651-30",
    Specimen_Type: "normal_tissue",
    Weight: 280.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00491",
    Specimen_ID: "C3N-00491-05",
    Slide_ID: "C3N-00491-25",
    Specimen_Type: "tumor_tissue",
    Weight: 299.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00492",
    Specimen_ID: "C3N-00492-05",
    Slide_ID: "C3N-00492-25",
    Specimen_Type: "normal_tissue",
    Weight: 405.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00494",
    Specimen_ID: "C3N-00494-02",
    Slide_ID: "C3N-00494-22",
    Specimen_Type: "tumor_tissue",
    Weight: 252.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-00494",
    Specimen_ID: "C3N-00494-01",
    Slide_ID: "C3N-00494-21",
    Specimen_Type: "tumor_tissue",
    Weight: 212.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00494",
    Specimen_ID: "C3N-00494-04",
    Slide_ID: "C3N-00494-24",
    Specimen_Type: "tumor_tissue",
    Weight: 250.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00492",
    Specimen_ID: "C3N-00492-04",
    Slide_ID: "C3N-00492-24",
    Specimen_Type: "tumor_tissue",
    Weight: 237.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-00494",
    Specimen_ID: "C3N-00494-03",
    Slide_ID: "C3N-00494-23",
    Specimen_Type: "tumor_tissue",
    Weight: 256.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00495",
    Specimen_ID: "C3N-00495-01",
    Slide_ID: "C3N-00495-21",
    Specimen_Type: "tumor_tissue",
    Weight: 332.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00494",
    Specimen_ID: "C3N-00494-05",
    Slide_ID: "C3N-00494-25",
    Specimen_Type: "normal_tissue",
    Weight: 270.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01524",
    Specimen_ID: "C3N-01524-02",
    Slide_ID: "C3N-01524-22",
    Specimen_Type: "tumor_tissue",
    Weight: 460.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type:
      "Rhabdoid variant of clear cell renal cell carcinoma.",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-01524",
    Specimen_ID: "C3N-01524-03",
    Slide_ID: "C3N-01524-23",
    Specimen_Type: "tumor_tissue",
    Weight: 550.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type:
      "Rhabdoid variant of clear cell renal cell carcinoma.",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01524",
    Specimen_ID: "C3N-01524-06",
    Slide_ID: "C3N-01524-26",
    Specimen_Type: "normal_tissue",
    Weight: 210.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01524",
    Specimen_ID: "C3N-01524-01",
    Slide_ID: "C3N-01524-21",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type:
      "Rhabdoid variant of clear cell renal cell carcinoma.",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-01522",
    Specimen_ID: "C3N-01522-03",
    Slide_ID: "C3N-01522-23",
    Specimen_Type: "tumor_tissue",
    Weight: 510.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-01522",
    Specimen_ID: "C3N-01522-06",
    Slide_ID: "C3N-01522-26",
    Specimen_Type: "normal_tissue",
    Weight: 220.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01522",
    Specimen_ID: "C3N-01522-01",
    Slide_ID: "C3N-01522-21",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-01522",
    Specimen_ID: "C3N-01522-02",
    Slide_ID: "C3N-01522-22",
    Specimen_Type: "tumor_tissue",
    Weight: 470.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01261",
    Specimen_ID: "C3N-01261-06",
    Slide_ID: "C3N-01261-26",
    Specimen_Type: "normal_tissue",
    Weight: 210.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01261",
    Specimen_ID: "C3N-01261-03",
    Slide_ID: "C3N-01261-23",
    Specimen_Type: "tumor_tissue",
    Weight: 560.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-01261",
    Specimen_ID: "C3N-01261-02",
    Slide_ID: "C3N-01261-22",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01220",
    Specimen_ID: "C3N-01220-06",
    Slide_ID: "C3N-01220-26",
    Specimen_Type: "normal_tissue",
    Weight: 210.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01261",
    Specimen_ID: "C3N-01261-01",
    Slide_ID: "C3N-01261-21",
    Specimen_Type: "tumor_tissue",
    Weight: 480.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01220",
    Specimen_ID: "C3N-01220-02",
    Slide_ID: "C3N-01220-22",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type:
      "Clear cell renal cell carcinoma with rhabdoid differentiation",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-01220",
    Specimen_ID: "C3N-01220-03",
    Slide_ID: "C3N-01220-23",
    Specimen_Type: "tumor_tissue",
    Weight: 450.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type:
      "Clear cell renal cell carcinoma with rhabdoid differentiation",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-01220",
    Specimen_ID: "C3N-01220-01",
    Slide_ID: "C3N-01220-21",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type:
      "Clear cell renal cell carcinoma with rhabdoid differentiation",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01214",
    Specimen_ID: "C3N-01214-06",
    Slide_ID: "C3N-01214-26",
    Specimen_Type: "normal_tissue",
    Weight: 250.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01214",
    Specimen_ID: "C3N-01214-03",
    Slide_ID: "C3N-01214-23",
    Specimen_Type: "tumor_tissue",
    Weight: 420.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-01214",
    Specimen_ID: "C3N-01214-02",
    Slide_ID: "C3N-01214-22",
    Specimen_Type: "tumor_tissue",
    Weight: 465.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 50.0,
    Percent_Total_Cellularity: 30.0,
    Percent_Necrosis: 60.0,
  },
  {
    Case_ID: "C3N-01214",
    Specimen_ID: "C3N-01214-01",
    Slide_ID: "C3N-01214-21",
    Specimen_Type: "tumor_tissue",
    Weight: 450.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 50.0,
    Percent_Total_Cellularity: 40.0,
    Percent_Necrosis: 60.0,
  },
  {
    Case_ID: "C3N-01213",
    Specimen_ID: "C3N-01213-06",
    Slide_ID: "C3N-01213-26",
    Specimen_Type: "normal_tissue",
    Weight: 200.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01213",
    Specimen_ID: "C3N-01213-02",
    Slide_ID: "C3N-01213-22",
    Specimen_Type: "tumor_tissue",
    Weight: 450.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-01213",
    Specimen_ID: "C3N-01213-01",
    Slide_ID: "C3N-01213-21",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01885",
    Specimen_ID: "C3L-01885-03",
    Slide_ID: "C3L-01885-23",
    Specimen_Type: "tumor_tissue",
    Weight: 212.0,
    Tumor_Site: "Right",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-01885",
    Specimen_ID: "C3L-01885-06",
    Slide_ID: "C3L-01885-26",
    Specimen_Type: "normal_tissue",
    Weight: 396.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01885",
    Specimen_ID: "C3L-01885-02",
    Slide_ID: "C3L-01885-22",
    Specimen_Type: "tumor_tissue",
    Weight: 227.0,
    Tumor_Site: "Right",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 65.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-01885",
    Specimen_ID: "C3L-01885-01",
    Slide_ID: "C3L-01885-21",
    Specimen_Type: "tumor_tissue",
    Weight: 231.0,
    Tumor_Site: "Right",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 65.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01200",
    Specimen_ID: "C3N-01200-03",
    Slide_ID: "C3N-01200-23",
    Specimen_Type: "tumor_tissue",
    Weight: 410.0,
    Tumor_Site: "Complete replacement of the left kidney's parenchyma",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 60.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-01200",
    Specimen_ID: "C3N-01200-05",
    Slide_ID: "C3N-01200-25",
    Specimen_Type: "tumor_tissue",
    Weight: 420.0,
    Tumor_Site: "Complete replacement of the left kidney's parenchyma",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01200",
    Specimen_ID: "C3N-01200-04",
    Slide_ID: "C3N-01200-24",
    Specimen_Type: "tumor_tissue",
    Weight: 400.0,
    Tumor_Site: "Complete replacement of the left kidney's parenchyma",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01200",
    Specimen_ID: "C3N-01200-06",
    Slide_ID: "C3N-01200-26",
    Specimen_Type: "normal_tissue",
    Weight: 200.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01200",
    Specimen_ID: "C3N-01200-01",
    Slide_ID: "C3N-01200-21",
    Specimen_Type: "tumor_tissue",
    Weight: 400.0,
    Tumor_Site: "Complete replacement of the left kidney's parenchyma",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01200",
    Specimen_ID: "C3N-01200-02",
    Slide_ID: "C3N-01200-22",
    Specimen_Type: "tumor_tissue",
    Weight: 410.0,
    Tumor_Site: "Complete replacement of the left kidney's parenchyma",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00852",
    Specimen_ID: "C3N-00852-06",
    Slide_ID: "C3N-00852-26",
    Specimen_Type: "normal_tissue",
    Weight: 210.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00852",
    Specimen_ID: "C3N-00852-03",
    Slide_ID: "C3N-00852-23",
    Specimen_Type: "tumor_tissue",
    Weight: 415.0,
    Tumor_Site: "Entire kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00852",
    Specimen_ID: "C3N-00852-01",
    Slide_ID: "C3N-00852-21",
    Specimen_Type: "tumor_tissue",
    Weight: 400.0,
    Tumor_Site: "Entire kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00852",
    Specimen_ID: "C3N-00852-02",
    Slide_ID: "C3N-00852-22",
    Specimen_Type: "tumor_tissue",
    Weight: 430.0,
    Tumor_Site: "Entire kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01882",
    Specimen_ID: "C3L-01882-01",
    Slide_ID: "C3L-01882-21",
    Specimen_Type: "tumor_tissue",
    Weight: 284.0,
    Tumor_Site: "Left",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01882",
    Specimen_ID: "C3L-01882-06",
    Slide_ID: "C3L-01882-26",
    Specimen_Type: "normal_tissue",
    Weight: 257.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01861",
    Specimen_ID: "C3L-01861-06",
    Slide_ID: "C3L-01861-26",
    Specimen_Type: "normal_tissue",
    Weight: 235.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01861",
    Specimen_ID: "C3L-01861-03",
    Slide_ID: "C3L-01861-23",
    Specimen_Type: "tumor_tissue",
    Weight: 209.0,
    Tumor_Site: "Left",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01861",
    Specimen_ID: "C3L-01861-02",
    Slide_ID: "C3L-01861-22",
    Specimen_Type: "tumor_tissue",
    Weight: 218.0,
    Tumor_Site: "Left",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-01861",
    Specimen_ID: "C3L-01861-01",
    Slide_ID: "C3L-01861-21",
    Specimen_Type: "tumor_tissue",
    Weight: 212.0,
    Tumor_Site: "Left",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 50.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00435",
    Specimen_ID: "C3N-00435-06",
    Slide_ID: "C3N-00435-26",
    Specimen_Type: "normal_tissue",
    Weight: 280.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00435",
    Specimen_ID: "C3N-00435-04",
    Slide_ID: "C3N-00435-24",
    Specimen_Type: "tumor_tissue",
    Weight: 510.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type:
      "Clear cell renal cell carcinoma with secondary papillary pattern.",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-00435",
    Specimen_ID: "C3N-00435-07",
    Slide_ID: "C3N-00435-27",
    Specimen_Type: "normal_tissue",
    Weight: 380.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00435",
    Specimen_ID: "C3N-00435-05",
    Slide_ID: "C3N-00435-25",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type:
      "Clear cell renal cell carcinoma with secondary papillary pattern.",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00435",
    Specimen_ID: "C3N-00435-03",
    Slide_ID: "C3N-00435-23",
    Specimen_Type: "tumor_tissue",
    Weight: 480.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type:
      "Clear cell renal cell carcinoma with secondary papillary pattern.",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00435",
    Specimen_ID: "C3N-00435-02",
    Slide_ID: "C3N-00435-22",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type:
      "Clear cell renal cell carcinoma with secondary papillary pattern.",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00435",
    Specimen_ID: "C3N-00435-01",
    Slide_ID: "C3N-00435-21",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type:
      "Clear cell renal cell carcinoma with secondary papillary pattern.",
    Percent_Tumor_Nuclei: 70.0,
    Percent_Total_Cellularity: 65.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3L-01836",
    Specimen_ID: "C3L-01836-06",
    Slide_ID: "C3L-01836-26",
    Specimen_Type: "normal_tissue",
    Weight: 623.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01836",
    Specimen_ID: "C3L-01836-03",
    Slide_ID: "C3L-01836-23",
    Specimen_Type: "tumor_tissue",
    Weight: 221.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-01836",
    Specimen_ID: "C3L-01836-02",
    Slide_ID: "C3L-01836-22",
    Specimen_Type: "tumor_tissue",
    Weight: 195.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01836",
    Specimen_ID: "C3L-01836-01",
    Slide_ID: "C3L-01836-21",
    Specimen_Type: "tumor_tissue",
    Weight: 245.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 60.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00390",
    Specimen_ID: "C3N-00390-07",
    Slide_ID: "C3N-00390-27",
    Specimen_Type: "normal_tissue",
    Weight: 200.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00390",
    Specimen_ID: "C3N-00390-04",
    Slide_ID: "C3N-00390-24",
    Specimen_Type: "tumor_tissue",
    Weight: 398.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3N-00390",
    Specimen_ID: "C3N-00390-03",
    Slide_ID: "C3N-00390-23",
    Specimen_Type: "tumor_tissue",
    Weight: 400.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-00390",
    Specimen_ID: "C3N-00390-06",
    Slide_ID: "C3N-00390-26",
    Specimen_Type: "normal_tissue",
    Weight: 180.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00390",
    Specimen_ID: "C3N-00390-01",
    Slide_ID: "C3N-00390-21",
    Specimen_Type: "tumor_tissue",
    Weight: 380.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00390",
    Specimen_ID: "C3N-00390-02",
    Slide_ID: "C3N-00390-22",
    Specimen_Type: "tumor_tissue",
    Weight: 415.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00380",
    Specimen_ID: "C3N-00380-06",
    Slide_ID: "C3N-00380-26",
    Specimen_Type: "normal_tissue",
    Weight: 110.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00380",
    Specimen_ID: "C3N-00380-05",
    Slide_ID: "C3N-00380-25",
    Specimen_Type: "tumor_tissue",
    Weight: 520.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00380",
    Specimen_ID: "C3N-00380-03",
    Slide_ID: "C3N-00380-23",
    Specimen_Type: "tumor_tissue",
    Weight: 510.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00380",
    Specimen_ID: "C3N-00380-04",
    Slide_ID: "C3N-00380-24",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00380",
    Specimen_ID: "C3N-00380-01",
    Slide_ID: "C3N-00380-21",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00380",
    Specimen_ID: "C3N-00380-02",
    Slide_ID: "C3N-00380-22",
    Specimen_Type: "tumor_tissue",
    Weight: 490.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01607",
    Specimen_ID: "C3L-01607-06",
    Slide_ID: "C3L-01607-26",
    Specimen_Type: "normal_tissue",
    Weight: 571.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01607",
    Specimen_ID: "C3L-01607-03",
    Slide_ID: "C3L-01607-23",
    Specimen_Type: "tumor_tissue",
    Weight: 419.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01607",
    Specimen_ID: "C3L-01607-02",
    Slide_ID: "C3L-01607-22",
    Specimen_Type: "tumor_tissue",
    Weight: 474.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01607",
    Specimen_ID: "C3L-01607-01",
    Slide_ID: "C3L-01607-21",
    Specimen_Type: "tumor_tissue",
    Weight: 455.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01603",
    Specimen_ID: "C3L-01603-06",
    Slide_ID: "C3L-01603-26",
    Specimen_Type: "normal_tissue",
    Weight: 276.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01603",
    Specimen_ID: "C3L-01603-03",
    Slide_ID: "C3L-01603-23",
    Specimen_Type: "tumor_tissue",
    Weight: 246.0,
    Tumor_Site: "Left Upper Pole, Lateral aspect",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01603",
    Specimen_ID: "C3L-01603-02",
    Slide_ID: "C3L-01603-22",
    Specimen_Type: "tumor_tissue",
    Weight: 213.0,
    Tumor_Site: "Left Upper Pole, Lateral aspect",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01603",
    Specimen_ID: "C3L-01603-01",
    Slide_ID: "C3L-01603-21",
    Specimen_Type: "tumor_tissue",
    Weight: 212.0,
    Tumor_Site: "Left Upper Pole, Lateral aspect",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01560",
    Specimen_ID: "C3L-01560-02",
    Slide_ID: "C3L-01560-22",
    Specimen_Type: "normal_tissue",
    Weight: 110.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01553",
    Specimen_ID: "C3L-01553-02",
    Slide_ID: "C3L-01553-22",
    Specimen_Type: "normal_tissue",
    Weight: 90.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01557",
    Specimen_ID: "C3L-01557-02",
    Slide_ID: "C3L-01557-22",
    Specimen_Type: "normal_tissue",
    Weight: 90.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01560",
    Specimen_ID: "C3L-01560-01",
    Slide_ID: "C3L-01560-21",
    Specimen_Type: "tumor_tissue",
    Weight: 200.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01557",
    Specimen_ID: "C3L-01557-01",
    Slide_ID: "C3L-01557-21",
    Specimen_Type: "tumor_tissue",
    Weight: 220.0,
    Tumor_Site: "Tumor invades the lower and middle poles",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-01553",
    Specimen_ID: "C3L-01553-01",
    Slide_ID: "C3L-01553-21",
    Specimen_Type: "tumor_tissue",
    Weight: 200.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01352",
    Specimen_ID: "C3L-01352-03",
    Slide_ID: "C3L-01352-23",
    Specimen_Type: "normal_tissue",
    Weight: 20.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01352",
    Specimen_ID: "C3L-01352-02",
    Slide_ID: "C3L-01352-22",
    Specimen_Type: "tumor_tissue",
    Weight: 120.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01352",
    Specimen_ID: "C3L-01352-01",
    Slide_ID: "C3L-01352-21",
    Specimen_Type: "tumor_tissue",
    Weight: 160.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01313",
    Specimen_ID: "C3L-01313-06",
    Slide_ID: "C3L-01313-26",
    Specimen_Type: "normal_tissue",
    Weight: 390.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01313",
    Specimen_ID: "C3L-01313-03",
    Slide_ID: "C3L-01313-23",
    Specimen_Type: "tumor_tissue",
    Weight: 370.0,
    Tumor_Site: "Mid to inferior aspect",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01302",
    Specimen_ID: "C3L-01302-06",
    Slide_ID: "C3L-01302-26",
    Specimen_Type: "normal_tissue",
    Weight: 259.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01302",
    Specimen_ID: "C3L-01302-03",
    Slide_ID: "C3L-01302-23",
    Specimen_Type: "tumor_tissue",
    Weight: 334.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3L-01288",
    Specimen_ID: "C3L-01288-03",
    Slide_ID: "C3L-01288-23",
    Specimen_Type: "tumor_tissue",
    Weight: 209.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-01287",
    Specimen_ID: "C3L-01287-03",
    Slide_ID: "C3L-01287-23",
    Specimen_Type: "tumor_tissue",
    Weight: 356.0,
    Tumor_Site: "Right Inferior",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01287",
    Specimen_ID: "C3L-01287-06",
    Slide_ID: "C3L-01287-26",
    Specimen_Type: "normal_tissue",
    Weight: 289.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01288",
    Specimen_ID: "C3L-01288-02",
    Slide_ID: "C3L-01288-22",
    Specimen_Type: "tumor_tissue",
    Weight: 211.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-01287",
    Specimen_ID: "C3L-01287-02",
    Slide_ID: "C3L-01287-22",
    Specimen_Type: "tumor_tissue",
    Weight: 440.0,
    Tumor_Site: "Right Inferior",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01287",
    Specimen_ID: "C3L-01287-01",
    Slide_ID: "C3L-01287-21",
    Specimen_Type: "tumor_tissue",
    Weight: 400.0,
    Tumor_Site: "Right Inferior",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3L-01286",
    Specimen_ID: "C3L-01286-06",
    Slide_ID: "C3L-01286-26",
    Specimen_Type: "normal_tissue",
    Weight: 296.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01286",
    Specimen_ID: "C3L-01286-03",
    Slide_ID: "C3L-01286-23",
    Specimen_Type: "tumor_tissue",
    Weight: 350.0,
    Tumor_Site:
      "Hilum, recurrent clear cell renal cell carcinoma, original tumor had sarcomatoid features",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-01286",
    Specimen_ID: "C3L-01286-02",
    Slide_ID: "C3L-01286-22",
    Specimen_Type: "tumor_tissue",
    Weight: 350.0,
    Tumor_Site:
      "Hilum, recurrent clear cell renal cell carcinoma, original tumor had sarcomatoid features",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01286",
    Specimen_ID: "C3L-01286-01",
    Slide_ID: "C3L-01286-21",
    Specimen_Type: "tumor_tissue",
    Weight: 364.0,
    Tumor_Site:
      "Hilum, recurrent clear cell renal cell carcinoma, original tumor had sarcomatoid features",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01283",
    Specimen_ID: "C3L-01283-01",
    Slide_ID: "C3L-01283-21",
    Specimen_Type: "tumor_tissue",
    Weight: 290.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-01281",
    Specimen_ID: "C3L-01281-03",
    Slide_ID: "C3L-01281-23",
    Specimen_Type: "tumor_tissue",
    Weight: 297.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-01281",
    Specimen_ID: "C3L-01281-06",
    Slide_ID: "C3L-01281-26",
    Specimen_Type: "normal_tissue",
    Weight: 399.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-01281",
    Specimen_ID: "C3L-01281-02",
    Slide_ID: "C3L-01281-22",
    Specimen_Type: "tumor_tissue",
    Weight: 294.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-01281",
    Specimen_ID: "C3L-01281-01",
    Slide_ID: "C3L-01281-21",
    Specimen_Type: "tumor_tissue",
    Weight: 261.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-00917",
    Specimen_ID: "C3L-00917-06",
    Slide_ID: "C3L-00917-26",
    Specimen_Type: "normal_tissue",
    Weight: 235.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00917",
    Specimen_ID: "C3L-00917-03",
    Slide_ID: "C3L-00917-23",
    Specimen_Type: "tumor_tissue",
    Weight: 182.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00917",
    Specimen_ID: "C3L-00917-01",
    Slide_ID: "C3L-00917-21",
    Specimen_Type: "tumor_tissue",
    Weight: 289.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00910",
    Specimen_ID: "C3L-00910-06",
    Slide_ID: "C3L-00910-26",
    Specimen_Type: "normal_tissue",
    Weight: 427.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00910",
    Specimen_ID: "C3L-00910-02",
    Slide_ID: "C3L-00910-22",
    Specimen_Type: "tumor_tissue",
    Weight: 604.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00910",
    Specimen_ID: "C3L-00910-01",
    Slide_ID: "C3L-00910-21",
    Specimen_Type: "tumor_tissue",
    Weight: 871.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-00908",
    Specimen_ID: "C3L-00908-06",
    Slide_ID: "C3L-00908-26",
    Specimen_Type: "normal_tissue",
    Weight: 303.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00908",
    Specimen_ID: "C3L-00908-03",
    Slide_ID: "C3L-00908-23",
    Specimen_Type: "tumor_tissue",
    Weight: 196.0,
    Tumor_Site: "Left Central Anterior",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 0.0,
    Percent_Total_Cellularity: 10.0,
    Percent_Necrosis: 80.0,
  },
  {
    Case_ID: "C3L-00908",
    Specimen_ID: "C3L-00908-01",
    Slide_ID: "C3L-00908-21",
    Specimen_Type: "tumor_tissue",
    Weight: 313.0,
    Tumor_Site: "Left Central Anterior",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00907",
    Specimen_ID: "C3L-00907-06",
    Slide_ID: "C3L-00907-26",
    Specimen_Type: "normal_tissue",
    Weight: 600.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00907",
    Specimen_ID: "C3L-00907-03",
    Slide_ID: "C3L-00907-23",
    Specimen_Type: "tumor_tissue",
    Weight: 520.0,
    Tumor_Site: "Right Lower Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00907",
    Specimen_ID: "C3L-00907-02",
    Slide_ID: "C3L-00907-22",
    Specimen_Type: "tumor_tissue",
    Weight: 573.0,
    Tumor_Site: "Right Lower Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00907",
    Specimen_ID: "C3L-00907-01",
    Slide_ID: "C3L-00907-21",
    Specimen_Type: "tumor_tissue",
    Weight: 900.0,
    Tumor_Site: "Right Lower Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00902",
    Specimen_ID: "C3L-00902-06",
    Slide_ID: "C3L-00902-26",
    Specimen_Type: "normal_tissue",
    Weight: 310.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00902",
    Specimen_ID: "C3L-00902-02",
    Slide_ID: "C3L-00902-22",
    Specimen_Type: "tumor_tissue",
    Weight: 474.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-00902",
    Specimen_ID: "C3L-00902-01",
    Slide_ID: "C3L-00902-21",
    Specimen_Type: "tumor_tissue",
    Weight: 386.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00817",
    Specimen_ID: "C3L-00817-02",
    Slide_ID: "C3L-00817-22",
    Specimen_Type: "normal_tissue",
    Weight: 100.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00817",
    Specimen_ID: "C3L-00817-01",
    Slide_ID: "C3L-00817-21",
    Specimen_Type: "tumor_tissue",
    Weight: 210.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00814",
    Specimen_ID: "C3L-00814-02",
    Slide_ID: "C3L-00814-22",
    Specimen_Type: "normal_tissue",
    Weight: 100.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00813",
    Specimen_ID: "C3L-00813-01",
    Slide_ID: "C3L-00813-21",
    Specimen_Type: "tumor_tissue",
    Weight: 220.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00813",
    Specimen_ID: "C3L-00813-02",
    Slide_ID: "C3L-00813-22",
    Specimen_Type: "normal_tissue",
    Weight: 70.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00812",
    Specimen_ID: "C3L-00812-01",
    Slide_ID: "C3L-00812-21",
    Specimen_Type: "tumor_tissue",
    Weight: 200.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00814",
    Specimen_ID: "C3L-00814-01",
    Slide_ID: "C3L-00814-21",
    Specimen_Type: "tumor_tissue",
    Weight: 230.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00812",
    Specimen_ID: "C3L-00812-02",
    Slide_ID: "C3L-00812-22",
    Specimen_Type: "normal_tissue",
    Weight: 100.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00800",
    Specimen_ID: "C3L-00800-02",
    Slide_ID: "C3L-00800-22",
    Specimen_Type: "normal_tissue",
    Weight: 100.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00800",
    Specimen_ID: "C3L-00800-01",
    Slide_ID: "C3L-00800-21",
    Specimen_Type: "tumor_tissue",
    Weight: 200.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00799",
    Specimen_ID: "C3L-00799-02",
    Slide_ID: "C3L-00799-22",
    Specimen_Type: "normal_tissue",
    Weight: 140.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00799",
    Specimen_ID: "C3L-00799-01",
    Slide_ID: "C3L-00799-21",
    Specimen_Type: "tumor_tissue",
    Weight: 230.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00796",
    Specimen_ID: "C3L-00796-02",
    Slide_ID: "C3L-00796-22",
    Specimen_Type: "normal_tissue",
    Weight: 110.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00796",
    Specimen_ID: "C3L-00796-01",
    Slide_ID: "C3L-00796-21",
    Specimen_Type: "tumor_tissue",
    Weight: 200.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 55.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00791",
    Specimen_ID: "C3L-00791-02",
    Slide_ID: "C3L-00791-22",
    Specimen_Type: "normal_tissue",
    Weight: 80.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00792",
    Specimen_ID: "C3L-00792-01",
    Slide_ID: "C3L-00792-21",
    Specimen_Type: "tumor_tissue",
    Weight: 200.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-00790",
    Specimen_ID: "C3L-00790-02",
    Slide_ID: "C3L-00790-22",
    Specimen_Type: "normal_tissue",
    Weight: 90.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00792",
    Specimen_ID: "C3L-00792-02",
    Slide_ID: "C3L-00792-22",
    Specimen_Type: "normal_tissue",
    Weight: 100.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00790",
    Specimen_ID: "C3L-00790-01",
    Slide_ID: "C3L-00790-21",
    Specimen_Type: "tumor_tissue",
    Weight: 240.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00766",
    Specimen_ID: "C3L-00766-01",
    Slide_ID: "C3L-00766-21",
    Specimen_Type: "tumor_tissue",
    Weight: 230.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-00766",
    Specimen_ID: "C3L-00766-02",
    Slide_ID: "C3L-00766-22",
    Specimen_Type: "normal_tissue",
    Weight: 130.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00765",
    Specimen_ID: "C3L-00765-02",
    Slide_ID: "C3L-00765-22",
    Specimen_Type: "normal_tissue",
    Weight: 120.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00765",
    Specimen_ID: "C3L-00765-01",
    Slide_ID: "C3L-00765-21",
    Specimen_Type: "tumor_tissue",
    Weight: 230.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 55.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-01808",
    Specimen_ID: "C3N-01808-07",
    Slide_ID: "C3N-01808-27",
    Specimen_Type: "normal_tissue",
    Weight: 175.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01808",
    Specimen_ID: "C3N-01808-02",
    Slide_ID: "C3N-01808-22",
    Specimen_Type: "tumor_tissue",
    Weight: 144.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-01808",
    Specimen_ID: "C3N-01808-03",
    Slide_ID: "C3N-01808-23",
    Specimen_Type: "tumor_tissue",
    Weight: 67.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-01808",
    Specimen_ID: "C3N-01808-06",
    Slide_ID: "C3N-01808-26",
    Specimen_Type: "normal_tissue",
    Weight: 195.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-01808",
    Specimen_ID: "C3N-01808-01",
    Slide_ID: "C3N-01808-21",
    Specimen_Type: "tumor_tissue",
    Weight: 219.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3L-00610",
    Specimen_ID: "C3L-00610-01",
    Slide_ID: "C3L-00610-21",
    Specimen_Type: "tumor_tissue",
    Weight: 350.0,
    Tumor_Site: 'Reports only indicate "deep renal tumor"',
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00610",
    Specimen_ID: "C3L-00610-02",
    Slide_ID: "C3L-00610-22",
    Specimen_Type: "normal_tissue",
    Weight: 90.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00607",
    Specimen_ID: "C3L-00607-02",
    Slide_ID: "C3L-00607-22",
    Specimen_Type: "normal_tissue",
    Weight: 170.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00607",
    Specimen_ID: "C3L-00607-01",
    Slide_ID: "C3L-00607-21",
    Specimen_Type: "tumor_tissue",
    Weight: 230.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00606",
    Specimen_ID: "C3L-00606-06",
    Slide_ID: "C3L-00606-26",
    Specimen_Type: "normal_tissue",
    Weight: 349.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00606",
    Specimen_ID: "C3L-00606-03",
    Slide_ID: "C3L-00606-23",
    Specimen_Type: "tumor_tissue",
    Weight: 387.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00606",
    Specimen_ID: "C3L-00606-02",
    Slide_ID: "C3L-00606-22",
    Specimen_Type: "tumor_tissue",
    Weight: 374.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00606",
    Specimen_ID: "C3L-00606-01",
    Slide_ID: "C3L-00606-21",
    Specimen_Type: "tumor_tissue",
    Weight: 438.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-00953",
    Specimen_ID: "C3N-00953-07",
    Slide_ID: "C3N-00953-27",
    Specimen_Type: "normal_tissue",
    Weight: 130.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00953",
    Specimen_ID: "C3N-00953-06",
    Slide_ID: "C3N-00953-26",
    Specimen_Type: "normal_tissue",
    Weight: 149.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00953",
    Specimen_ID: "C3N-00953-03",
    Slide_ID: "C3N-00953-23",
    Specimen_Type: "tumor_tissue",
    Weight: 193.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00953",
    Specimen_ID: "C3N-00953-02",
    Slide_ID: "C3N-00953-22",
    Specimen_Type: "tumor_tissue",
    Weight: 252.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00953",
    Specimen_ID: "C3N-00953-01",
    Slide_ID: "C3N-00953-21",
    Specimen_Type: "tumor_tissue",
    Weight: 155.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-01213",
    Specimen_ID: "C3N-01213-03",
    Slide_ID: "C3N-01213-23",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00733",
    Specimen_ID: "C3N-00733-07",
    Slide_ID: "C3N-00733-27",
    Specimen_Type: "normal_tissue",
    Weight: 184.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00733",
    Specimen_ID: "C3N-00733-06",
    Slide_ID: "C3N-00733-26",
    Specimen_Type: "normal_tissue",
    Weight: 163.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00733",
    Specimen_ID: "C3N-00733-03",
    Slide_ID: "C3N-00733-23",
    Specimen_Type: "tumor_tissue",
    Weight: 241.0,
    Tumor_Site: "Involves all three poles",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00733",
    Specimen_ID: "C3N-00733-02",
    Slide_ID: "C3N-00733-22",
    Specimen_Type: "tumor_tissue",
    Weight: 328.0,
    Tumor_Site: "Involves all three poles",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00733",
    Specimen_ID: "C3N-00733-01",
    Slide_ID: "C3N-00733-21",
    Specimen_Type: "tumor_tissue",
    Weight: 540.0,
    Tumor_Site: "Involves all three poles",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00246",
    Specimen_ID: "C3N-00246-07",
    Slide_ID: "C3N-00246-27",
    Specimen_Type: "normal_tissue",
    Weight: 41.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00246",
    Specimen_ID: "C3N-00246-06",
    Slide_ID: "C3N-00246-26",
    Specimen_Type: "normal_tissue",
    Weight: 51.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00246",
    Specimen_ID: "C3N-00246-02",
    Slide_ID: "C3N-00246-22",
    Specimen_Type: "tumor_tissue",
    Weight: 50.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00246",
    Specimen_ID: "C3N-00246-03",
    Slide_ID: "C3N-00246-23",
    Specimen_Type: "tumor_tissue",
    Weight: 102.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3N-00244",
    Specimen_ID: "C3N-00244-07",
    Slide_ID: "C3N-00244-27",
    Specimen_Type: "normal_tissue",
    Weight: 104.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00246",
    Specimen_ID: "C3N-00246-01",
    Slide_ID: "C3N-00246-21",
    Specimen_Type: "tumor_tissue",
    Weight: 58.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00244",
    Specimen_ID: "C3N-00244-06",
    Slide_ID: "C3N-00244-26",
    Specimen_Type: "normal_tissue",
    Weight: 92.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00244",
    Specimen_ID: "C3N-00244-02",
    Slide_ID: "C3N-00244-22",
    Specimen_Type: "tumor_tissue",
    Weight: 92.0,
    Tumor_Site: "upper and middle pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00244",
    Specimen_ID: "C3N-00244-03",
    Slide_ID: "C3N-00244-23",
    Specimen_Type: "tumor_tissue",
    Weight: 91.0,
    Tumor_Site: "upper and middle pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00244",
    Specimen_ID: "C3N-00244-01",
    Slide_ID: "C3N-00244-21",
    Specimen_Type: "tumor_tissue",
    Weight: 88.0,
    Tumor_Site: "upper and middle pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00242",
    Specimen_ID: "C3N-00242-06",
    Slide_ID: "C3N-00242-26",
    Specimen_Type: "normal_tissue",
    Weight: 329.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00242",
    Specimen_ID: "C3N-00242-02",
    Slide_ID: "C3N-00242-22",
    Specimen_Type: "tumor_tissue",
    Weight: 185.0,
    Tumor_Site: "RIGHT, unspecified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00242",
    Specimen_ID: "C3N-00242-01",
    Slide_ID: "C3N-00242-21",
    Specimen_Type: "tumor_tissue",
    Weight: 160.0,
    Tumor_Site: "RIGHT, unspecified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00194",
    Specimen_ID: "C3N-00194-03",
    Slide_ID: "C3N-00194-23",
    Specimen_Type: "tumor_tissue",
    Weight: 210.0,
    Tumor_Site: "MID TO LOWER POLE",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 0.0,
    Percent_Total_Cellularity: 10.0,
    Percent_Necrosis: 95.0,
  },
  {
    Case_ID: "C3N-00194",
    Specimen_ID: "C3N-00194-07",
    Slide_ID: "C3N-00194-27",
    Specimen_Type: "normal_tissue",
    Weight: 130.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00194",
    Specimen_ID: "C3N-00194-04",
    Slide_ID: "C3N-00194-24",
    Specimen_Type: "tumor_tissue",
    Weight: 280.0,
    Tumor_Site: "MID TO LOWER POLE",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 30.0,
    Percent_Necrosis: 70.0,
  },
  {
    Case_ID: "C3N-00194",
    Specimen_ID: "C3N-00194-06",
    Slide_ID: "C3N-00194-26",
    Specimen_Type: "normal_tissue",
    Weight: 149.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00194",
    Specimen_ID: "C3N-00194-02",
    Slide_ID: "C3N-00194-22",
    Specimen_Type: "tumor_tissue",
    Weight: 231.0,
    Tumor_Site: "MID TO LOWER POLE",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00194",
    Specimen_ID: "C3N-00194-01",
    Slide_ID: "C3N-00194-21",
    Specimen_Type: "tumor_tissue",
    Weight: 178.0,
    Tumor_Site: "MID TO LOWER POLE",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 20.0,
    Percent_Necrosis: 70.0,
  },
  {
    Case_ID: "C3L-00583",
    Specimen_ID: "C3L-00583-02",
    Slide_ID: "C3L-00583-22",
    Specimen_Type: "tumor_tissue",
    Weight: 230.0,
    Tumor_Site: "Central",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00583",
    Specimen_ID: "C3L-00583-06",
    Slide_ID: "C3L-00583-26",
    Specimen_Type: "normal_tissue",
    Weight: 283.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00583",
    Specimen_ID: "C3L-00583-03",
    Slide_ID: "C3L-00583-23",
    Specimen_Type: "tumor_tissue",
    Weight: 277.0,
    Tumor_Site: "Central",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00583",
    Specimen_ID: "C3L-00583-01",
    Slide_ID: "C3L-00583-21",
    Specimen_Type: "tumor_tissue",
    Weight: 348.0,
    Tumor_Site: "Central",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00581",
    Specimen_ID: "C3L-00581-06",
    Slide_ID: "C3L-00581-26",
    Specimen_Type: "normal_tissue",
    Weight: 404.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00581",
    Specimen_ID: "C3L-00581-03",
    Slide_ID: "C3L-00581-23",
    Specimen_Type: "tumor_tissue",
    Weight: 368.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00581",
    Specimen_ID: "C3L-00581-02",
    Slide_ID: "C3L-00581-22",
    Specimen_Type: "tumor_tissue",
    Weight: 304.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00581",
    Specimen_ID: "C3L-00581-01",
    Slide_ID: "C3L-00581-21",
    Specimen_Type: "tumor_tissue",
    Weight: 373.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00154",
    Specimen_ID: "C3N-00154-02",
    Slide_ID: "C3N-00154-22",
    Specimen_Type: "tumor_tissue",
    Weight: 600.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00561",
    Specimen_ID: "C3L-00561-01",
    Slide_ID: "C3L-00561-21",
    Specimen_Type: "tumor_tissue",
    Weight: 426.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00561",
    Specimen_ID: "C3L-00561-06",
    Slide_ID: "C3L-00561-26",
    Specimen_Type: "normal_tissue",
    Weight: 330.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00154",
    Specimen_ID: "C3N-00154-01",
    Slide_ID: "C3N-00154-21",
    Specimen_Type: "tumor_tissue",
    Weight: 500.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 70.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00561",
    Specimen_ID: "C3L-00561-02",
    Slide_ID: "C3L-00561-22",
    Specimen_Type: "tumor_tissue",
    Weight: 367.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-00573",
    Specimen_ID: "C3N-00573-04",
    Slide_ID: "C3N-00573-24",
    Specimen_Type: "tumor_tissue",
    Weight: 300.0,
    Tumor_Site: "Left kidney, unspecified pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00573",
    Specimen_ID: "C3N-00573-03",
    Slide_ID: "C3N-00573-23",
    Specimen_Type: "tumor_tissue",
    Weight: 300.0,
    Tumor_Site: "Left kidney, unspecified pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00573",
    Specimen_ID: "C3N-00573-02",
    Slide_ID: "C3N-00573-22",
    Specimen_Type: "tumor_tissue",
    Weight: 300.0,
    Tumor_Site: "Left kidney, unspecified pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00577",
    Specimen_ID: "C3N-00577-07",
    Slide_ID: "C3N-00577-27",
    Specimen_Type: "normal_tissue",
    Weight: 300.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00577",
    Specimen_ID: "C3N-00577-06",
    Slide_ID: "C3N-00577-26",
    Specimen_Type: "normal_tissue",
    Weight: 300.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00577",
    Specimen_ID: "C3N-00577-04",
    Slide_ID: "C3N-00577-24",
    Specimen_Type: "tumor_tissue",
    Weight: 300.0,
    Tumor_Site: "Left kidney, not otherwise specified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-00577",
    Specimen_ID: "C3N-00577-03",
    Slide_ID: "C3N-00577-23",
    Specimen_Type: "tumor_tissue",
    Weight: 300.0,
    Tumor_Site: "Left kidney, not otherwise specified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 50.0,
    Percent_Total_Cellularity: 20.0,
    Percent_Necrosis: 80.0,
  },
  {
    Case_ID: "C3N-00577",
    Specimen_ID: "C3N-00577-02",
    Slide_ID: "C3N-00577-22",
    Specimen_Type: "tumor_tissue",
    Weight: 300.0,
    Tumor_Site: "Left kidney, not otherwise specified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 20.0,
  },
  {
    Case_ID: "C3N-00577",
    Specimen_ID: "C3N-00577-01",
    Slide_ID: "C3N-00577-21",
    Specimen_Type: "tumor_tissue",
    Weight: 300.0,
    Tumor_Site: "Left kidney, not otherwise specified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 60.0,
    Percent_Total_Cellularity: 35.0,
    Percent_Necrosis: 70.0,
  },
  {
    Case_ID: "C3L-00103",
    Specimen_ID: "C3L-00103-06",
    Slide_ID: "C3L-00103-26",
    Specimen_Type: "normal_tissue",
    Weight: 148.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00103",
    Specimen_ID: "C3L-00103-03",
    Slide_ID: "C3L-00103-23",
    Specimen_Type: "tumor_tissue",
    Weight: 157.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00103",
    Specimen_ID: "C3L-00103-02",
    Slide_ID: "C3L-00103-22",
    Specimen_Type: "tumor_tissue",
    Weight: 201.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00103",
    Specimen_ID: "C3L-00103-01",
    Slide_ID: "C3L-00103-21",
    Specimen_Type: "tumor_tissue",
    Weight: 178.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00573",
    Specimen_ID: "C3N-00573-07",
    Slide_ID: "C3N-00573-27",
    Specimen_Type: "normal_tissue",
    Weight: 300.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00448",
    Specimen_ID: "C3L-00448-06",
    Slide_ID: "C3L-00448-26",
    Specimen_Type: "normal_tissue",
    Weight: 249.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00573",
    Specimen_ID: "C3N-00573-06",
    Slide_ID: "C3N-00573-26",
    Specimen_Type: "normal_tissue",
    Weight: 300.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00447",
    Specimen_ID: "C3L-00447-06",
    Slide_ID: "C3L-00447-26",
    Specimen_Type: "normal_tissue",
    Weight: 236.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00448",
    Specimen_ID: "C3L-00448-01",
    Slide_ID: "C3L-00448-21",
    Specimen_Type: "tumor_tissue",
    Weight: 259.0,
    Tumor_Site: "mid anterior",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00447",
    Specimen_ID: "C3L-00447-02",
    Slide_ID: "C3L-00447-22",
    Specimen_Type: "tumor_tissue",
    Weight: 332.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00573",
    Specimen_ID: "C3N-00573-01",
    Slide_ID: "C3N-00573-21",
    Specimen_Type: "tumor_tissue",
    Weight: 300.0,
    Tumor_Site: "Left kidney, unspecified pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 65.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-00447",
    Specimen_ID: "C3L-00447-03",
    Slide_ID: "C3L-00447-23",
    Specimen_Type: "tumor_tissue",
    Weight: 281.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-00447",
    Specimen_ID: "C3L-00447-01",
    Slide_ID: "C3L-00447-21",
    Specimen_Type: "tumor_tissue",
    Weight: 230.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00011",
    Specimen_ID: "C3L-00011-06",
    Slide_ID: "C3L-00011-26",
    Specimen_Type: "normal_tissue",
    Weight: 241.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00011",
    Specimen_ID: "C3L-00011-01",
    Slide_ID: "C3L-00011-21",
    Specimen_Type: "tumor_tissue",
    Weight: 272.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "unclassified with rhaboid features",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00097",
    Specimen_ID: "C3L-00097-06",
    Slide_ID: "C3L-00097-26",
    Specimen_Type: "normal_tissue",
    Weight: 222.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00004",
    Specimen_ID: "C3L-00004-01",
    Slide_ID: "C3L-00004-21",
    Specimen_Type: "tumor_tissue",
    Weight: 484.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00097",
    Specimen_ID: "C3L-00097-01",
    Slide_ID: "C3L-00097-21",
    Specimen_Type: "tumor_tissue",
    Weight: 218.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00096",
    Specimen_ID: "C3L-00096-06",
    Slide_ID: "C3L-00096-26",
    Specimen_Type: "normal_tissue",
    Weight: 524.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00096",
    Specimen_ID: "C3L-00096-01",
    Slide_ID: "C3L-00096-21",
    Specimen_Type: "tumor_tissue",
    Weight: 537.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00148",
    Specimen_ID: "C3N-00148-06",
    Slide_ID: "C3N-00148-26",
    Specimen_Type: "normal_tissue",
    Weight: 1420.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00088",
    Specimen_ID: "C3L-00088-02",
    Slide_ID: "C3L-00088-22",
    Specimen_Type: "tumor_tissue",
    Weight: 237.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00088",
    Specimen_ID: "C3L-00088-01",
    Slide_ID: "C3L-00088-21",
    Specimen_Type: "tumor_tissue",
    Weight: 306.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00148",
    Specimen_ID: "C3N-00148-03",
    Slide_ID: "C3N-00148-23",
    Specimen_Type: "tumor_tissue",
    Weight: 1990.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-00088",
    Specimen_ID: "C3L-00088-06",
    Slide_ID: "C3L-00088-26",
    Specimen_Type: "normal_tissue",
    Weight: 345.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00418",
    Specimen_ID: "C3L-00418-02",
    Slide_ID: "C3L-00418-22",
    Specimen_Type: "tumor_tissue",
    Weight: 253.0,
    Tumor_Site: "Inferior to Mid Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00418",
    Specimen_ID: "C3L-00418-03",
    Slide_ID: "C3L-00418-23",
    Specimen_Type: "tumor_tissue",
    Weight: 267.0,
    Tumor_Site: "Inferior to Mid Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00418",
    Specimen_ID: "C3L-00418-06",
    Slide_ID: "C3L-00418-26",
    Specimen_Type: "normal_tissue",
    Weight: 354.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00418",
    Specimen_ID: "C3L-00418-01",
    Slide_ID: "C3L-00418-21",
    Specimen_Type: "tumor_tissue",
    Weight: 428.0,
    Tumor_Site: "Inferior to Mid Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00416",
    Specimen_ID: "C3L-00416-02",
    Slide_ID: "C3L-00416-22",
    Specimen_Type: "tumor_tissue",
    Weight: 448.0,
    Tumor_Site: "HILAR",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00416",
    Specimen_ID: "C3L-00416-06",
    Slide_ID: "C3L-00416-26",
    Specimen_Type: "normal_tissue",
    Weight: 634.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00416",
    Specimen_ID: "C3L-00416-01",
    Slide_ID: "C3L-00416-21",
    Specimen_Type: "tumor_tissue",
    Weight: 440.0,
    Tumor_Site: "HILAR",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00369",
    Specimen_ID: "C3L-00369-03",
    Slide_ID: "C3L-00369-23",
    Specimen_Type: "tumor_tissue",
    Weight: 250.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-00369",
    Specimen_ID: "C3L-00369-06",
    Slide_ID: "C3L-00369-26",
    Specimen_Type: "normal_tissue",
    Weight: 264.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00369",
    Specimen_ID: "C3L-00369-02",
    Slide_ID: "C3L-00369-22",
    Specimen_Type: "tumor_tissue",
    Weight: 246.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00369",
    Specimen_ID: "C3L-00369-01",
    Slide_ID: "C3L-00369-21",
    Specimen_Type: "tumor_tissue",
    Weight: 300.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00148",
    Specimen_ID: "C3N-00148-04",
    Slide_ID: "C3N-00148-24",
    Specimen_Type: "tumor_tissue",
    Weight: 1330.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00148",
    Specimen_ID: "C3N-00148-01",
    Slide_ID: "C3N-00148-21",
    Specimen_Type: "tumor_tissue",
    Weight: 1840.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00079",
    Specimen_ID: "C3L-00079-06",
    Slide_ID: "C3L-00079-26",
    Specimen_Type: "normal_tissue",
    Weight: 363.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00079",
    Specimen_ID: "C3L-00079-01",
    Slide_ID: "C3L-00079-21",
    Specimen_Type: "tumor_tissue",
    Weight: 675.0,
    Tumor_Site: "mid-inferior",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 2.0,
  },
  {
    Case_ID: "C3L-00004",
    Specimen_ID: "C3L-00004-06",
    Slide_ID: "C3L-00004-26",
    Specimen_Type: "normal_tissue",
    Weight: 252.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00360",
    Specimen_ID: "C3L-00360-06",
    Slide_ID: "C3L-00360-26",
    Specimen_Type: "normal_tissue",
    Weight: 220.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00360",
    Specimen_ID: "C3L-00360-02",
    Slide_ID: "C3L-00360-22",
    Specimen_Type: "tumor_tissue",
    Weight: 204.0,
    Tumor_Site: "posterior central",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00360",
    Specimen_ID: "C3L-00360-01",
    Slide_ID: "C3L-00360-21",
    Specimen_Type: "tumor_tissue",
    Weight: 267.0,
    Tumor_Site: "posterior central",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00359",
    Specimen_ID: "C3L-00359-06",
    Slide_ID: "C3L-00359-26",
    Specimen_Type: "normal_tissue",
    Weight: 307.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00359",
    Specimen_ID: "C3L-00359-03",
    Slide_ID: "C3L-00359-23",
    Specimen_Type: "tumor_tissue",
    Weight: 450.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00359",
    Specimen_ID: "C3L-00359-02",
    Slide_ID: "C3L-00359-22",
    Specimen_Type: "tumor_tissue",
    Weight: 345.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00359",
    Specimen_ID: "C3L-00359-01",
    Slide_ID: "C3L-00359-21",
    Specimen_Type: "tumor_tissue",
    Weight: 480.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3L-00010",
    Specimen_ID: "C3L-00010-06",
    Slide_ID: "C3L-00010-26",
    Specimen_Type: "normal_tissue",
    Weight: 223.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00010",
    Specimen_ID: "C3L-00010-01",
    Slide_ID: "C3L-00010-21",
    Specimen_Type: "tumor_tissue",
    Weight: 575.0,
    Tumor_Site: "Upper pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3L-00183",
    Specimen_ID: "C3L-00183-06",
    Slide_ID: "C3L-00183-26",
    Specimen_Type: "normal_tissue",
    Weight: 25.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00183",
    Specimen_ID: "C3L-00183-04",
    Slide_ID: "C3L-00183-24",
    Specimen_Type: "normal_tissue",
    Weight: 58.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00183",
    Specimen_ID: "C3L-00183-05",
    Slide_ID: "C3L-00183-25",
    Specimen_Type: "tumor_tissue",
    Weight: 112.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00183",
    Specimen_ID: "C3L-00183-03",
    Slide_ID: "C3L-00183-23",
    Specimen_Type: "tumor_tissue",
    Weight: 97.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00183",
    Specimen_ID: "C3L-00183-02",
    Slide_ID: "C3L-00183-22",
    Specimen_Type: "normal_tissue",
    Weight: 71.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00183",
    Specimen_ID: "C3L-00183-01",
    Slide_ID: "C3L-00183-21",
    Specimen_Type: "tumor_tissue",
    Weight: 75.0,
    Tumor_Site: "Middle",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00150",
    Specimen_ID: "C3N-00150-07",
    Slide_ID: "C3N-00150-27",
    Specimen_Type: "normal_tissue",
    Weight: 1000.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00150",
    Specimen_ID: "C3N-00150-04",
    Slide_ID: "C3N-00150-24",
    Specimen_Type: "tumor_tissue",
    Weight: 1000.0,
    Tumor_Site: "Kidney, not otherwise specified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 65.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3L-00026",
    Specimen_ID: "C3L-00026-06",
    Slide_ID: "C3L-00026-26",
    Specimen_Type: "normal_tissue",
    Weight: 212.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3L-00026",
    Specimen_ID: "C3L-00026-01",
    Slide_ID: "C3L-00026-21",
    Specimen_Type: "tumor_tissue",
    Weight: 212.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 95.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00177",
    Specimen_ID: "C3N-00177-03",
    Slide_ID: "C3N-00177-23",
    Specimen_Type: "tumor_tissue",
    Weight: 200.0,
    Tumor_Site: "Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00177",
    Specimen_ID: "C3N-00177-07",
    Slide_ID: "C3N-00177-27",
    Specimen_Type: "normal_tissue",
    Weight: 200.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00177",
    Specimen_ID: "C3N-00177-06",
    Slide_ID: "C3N-00177-26",
    Specimen_Type: "normal_tissue",
    Weight: 200.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00177",
    Specimen_ID: "C3N-00177-02",
    Slide_ID: "C3N-00177-22",
    Specimen_Type: "tumor_tissue",
    Weight: 200.0,
    Tumor_Site: "Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 85.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00177",
    Specimen_ID: "C3N-00177-01",
    Slide_ID: "C3N-00177-21",
    Specimen_Type: "tumor_tissue",
    Weight: 200.0,
    Tumor_Site: "Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00150",
    Specimen_ID: "C3N-00150-06",
    Slide_ID: "C3N-00150-26",
    Specimen_Type: "normal_tissue",
    Weight: 1000.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00150",
    Specimen_ID: "C3N-00150-03",
    Slide_ID: "C3N-00150-23",
    Specimen_Type: "tumor_tissue",
    Weight: 1000.0,
    Tumor_Site: "Kidney, not otherwise specified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 60.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00150",
    Specimen_ID: "C3N-00150-01",
    Slide_ID: "C3N-00150-21",
    Specimen_Type: "tumor_tissue",
    Weight: 1000.0,
    Tumor_Site: "Kidney, not otherwise specified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 65.0,
    Percent_Necrosis: 15.0,
  },
  {
    Case_ID: "C3N-00150",
    Specimen_ID: "C3N-00150-02",
    Slide_ID: "C3N-00150-22",
    Specimen_Type: "tumor_tissue",
    Weight: 1000.0,
    Tumor_Site: "Kidney, not otherwise specified",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 90.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00149",
    Specimen_ID: "C3N-00149-07",
    Slide_ID: "C3N-00149-27",
    Specimen_Type: "normal_tissue",
    Weight: 480.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00149",
    Specimen_ID: "C3N-00149-03",
    Slide_ID: "C3N-00149-23",
    Specimen_Type: "tumor_tissue",
    Weight: 350.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 50.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00168",
    Specimen_ID: "C3N-00168-03",
    Slide_ID: "C3N-00168-23",
    Specimen_Type: "tumor_tissue",
    Weight: 250.0,
    Tumor_Site: "Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00168",
    Specimen_ID: "C3N-00168-07",
    Slide_ID: "C3N-00168-27",
    Specimen_Type: "normal_tissue",
    Weight: 200.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00646",
    Specimen_ID: "C3N-00646-02",
    Slide_ID: "C3N-00646-22",
    Specimen_Type: "tumor_tissue",
    Weight: 1960.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 50.0,
    Percent_Total_Cellularity: 30.0,
    Percent_Necrosis: 20.0,
  },
  {
    Case_ID: "C3N-00646",
    Specimen_ID: "C3N-00646-01",
    Slide_ID: "C3N-00646-21",
    Specimen_Type: "tumor_tissue",
    Weight: 1960.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 40.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3N-00168",
    Specimen_ID: "C3N-00168-06",
    Slide_ID: "C3N-00168-26",
    Specimen_Type: "normal_tissue",
    Weight: 200.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00168",
    Specimen_ID: "C3N-00168-04",
    Slide_ID: "C3N-00168-24",
    Specimen_Type: "tumor_tissue",
    Weight: 250.0,
    Tumor_Site: "Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 60.0,
    Percent_Total_Cellularity: 80.0,
    Percent_Necrosis: 0.0,
  },
  {
    Case_ID: "C3N-00168",
    Specimen_ID: "C3N-00168-02",
    Slide_ID: "C3N-00168-22",
    Specimen_Type: "tumor_tissue",
    Weight: 250.0,
    Tumor_Site: "Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00168",
    Specimen_ID: "C3N-00168-01",
    Slide_ID: "C3N-00168-21",
    Specimen_Type: "tumor_tissue",
    Weight: 250.0,
    Tumor_Site: "Pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 65.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00646",
    Specimen_ID: "C3N-00646-07",
    Slide_ID: "C3N-00646-27",
    Specimen_Type: "normal_tissue",
    Weight: 2210.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00646",
    Specimen_ID: "C3N-00646-06",
    Slide_ID: "C3N-00646-26",
    Specimen_Type: "normal_tissue",
    Weight: 1970.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00646",
    Specimen_ID: "C3N-00646-04",
    Slide_ID: "C3N-00646-24",
    Specimen_Type: "tumor_tissue",
    Weight: 1800.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 70.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00646",
    Specimen_ID: "C3N-00646-03",
    Slide_ID: "C3N-00646-23",
    Specimen_Type: "tumor_tissue",
    Weight: 1970.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 75.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00149",
    Specimen_ID: "C3N-00149-02",
    Slide_ID: "C3N-00149-22",
    Specimen_Type: "tumor_tissue",
    Weight: 400.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 80.0,
    Percent_Total_Cellularity: 60.0,
    Percent_Necrosis: 10.0,
  },
  {
    Case_ID: "C3N-00149",
    Specimen_ID: "C3N-00149-06",
    Slide_ID: "C3N-00149-26",
    Specimen_Type: "normal_tissue",
    Weight: 570.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00149",
    Specimen_ID: "C3N-00149-04",
    Slide_ID: "C3N-00149-24",
    Specimen_Type: "tumor_tissue",
    Weight: 384.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 40.0,
    Percent_Total_Cellularity: 25.0,
    Percent_Necrosis: 30.0,
  },
  {
    Case_ID: "C3N-00149",
    Specimen_ID: "C3N-00149-01",
    Slide_ID: "C3N-00149-21",
    Specimen_Type: "tumor_tissue",
    Weight: 400.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 1.0,
    Percent_Total_Cellularity: 5.0,
    Percent_Necrosis: 99.0,
  },
  {
    Case_ID: "C3N-00154",
    Specimen_ID: "C3N-00154-07",
    Slide_ID: "C3N-00154-27",
    Specimen_Type: "normal_tissue",
    Weight: 230.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00148",
    Specimen_ID: "C3N-00148-07",
    Slide_ID: "C3N-00148-27",
    Specimen_Type: "normal_tissue",
    Weight: 1580.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00148",
    Specimen_ID: "C3N-00148-02",
    Slide_ID: "C3N-00148-22",
    Specimen_Type: "tumor_tissue",
    Weight: 1930.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 65.0,
    Percent_Total_Cellularity: 50.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3N-00154",
    Specimen_ID: "C3N-00154-06",
    Slide_ID: "C3N-00154-26",
    Specimen_Type: "normal_tissue",
    Weight: 200.0,
    Tumor_Site: "",
    Tumor_Histological_Type: "",
    Percent_Tumor_Nuclei: -1.0,
    Percent_Total_Cellularity: -1.0,
    Percent_Necrosis: -1.0,
  },
  {
    Case_ID: "C3N-00154",
    Specimen_ID: "C3N-00154-04",
    Slide_ID: "C3N-00154-24",
    Specimen_Type: "tumor_tissue",
    Weight: 810.0,
    Tumor_Site: "Kidney",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 85.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 5.0,
  },
  {
    Case_ID: "C3L-00791",
    Specimen_ID: "C3L-00791-01",
    Slide_ID: "C3L-00791-21",
    Specimen_Type: "tumor_tissue",
    Weight: 220.0,
    Tumor_Site: "Lower pole",
    Tumor_Histological_Type: "Clear cell renal cell carcinoma",
    Percent_Tumor_Nuclei: 95.0,
    Percent_Total_Cellularity: 90.0,
    Percent_Necrosis: 0.0,
  },
];
